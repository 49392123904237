import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../../components/SEO';
import Layout from '../../components/layout';
import GetServiceItems from '../../components/getServiceItems';
import bg from '../../images/bg_image_trainings_page.jpg';
import logo from '../../images/Logo@2x.png';

export default props => {
  const { data, location } = props;
  const courseList = data.allPagecontentJson;
  const allCourses = data.allCoursesJson;

  return (
    <>
      <SEO
        title="Office-aiheiset koulutukset | Word, Powerpoint, Outlook"
        description="Etsikö Excel-, Word-, -Powerpoint kursseja? Meiltä kaikki Office -aiheiset koulutukset ketterästi ja edullisesti."
      />
      <Layout
        mainTitle={'Office-aiheiset koulutukset'}
        mainTitleClasses={'mt-3'}
        additionalTitle={'Yrityksellesi räätälöitynä'}
        headerBgStyles={{ background: 'url(' + bg + ')' }}
        headerTitleStyle={{ color: '#0086ef' }}
        headerAdditionalTitleStyle={{ color: '#019999' }}
        additionalTitleClassNames={'mb-3 mt-md-0 mb-md-3'}
        headerNavLinkStyle={{ color: '#7a7c81' }}
        logo={logo}
        {...props}
      >
        <div className="services">
          <GetServiceItems
            items={courseList.nodes[0].service_list[1].items}
            allCourses={allCourses}
          />
        </div>
      </Layout>
    </>
  );
};

// function CourseTopicSelector(props) {
//   return (
//     <div className="container course-topic-selector my-6">
//       <div className="row justify-content-center">
//         <div className="col-md-10">
//           <p className="lead">
//             Löydä itsellesi tai henkilöstöllesi sopiva koulutus alla olevasta
//             listasta. Koulutamme mm. seuraavia aiheita: Excel, Word, Powerpoint
//             ja Outlook. Kouluttajamme saapuvat teille sopivana aikana
//             yritykseenne paikanpäälle vetämään koulutuksen.
//           </p>
//           <p className="lead mb-0">
//             Toteutamme myös tehokkaita automaatioratkaisuja mm. Excel-makroja
//             hyödyntäen.{' '}
//             <Link to="/">Lue lisää automatisaatioratkaisuistamme.</Link>
//           </p>
//         </div>
//         <div className="col-md-8">
//           <div className="size-5 text-center mt-5 mb-5">Valitse aihe</div>
//           <ul className="list">
//             {props.topics.map((item, index) => {
//               return (
//                 <li
//                   className="list-item-wrapper mx-3 p-2"
//                   key={getKeyFromString(item.title)}
//                   onClick={() => props.executeScroll(item.link)}
//                 >
//                   <div className="item">{item.title}</div>
//                 </li>
//               );
//             })}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

export const query = graphql`
  query MyQuery {
    allPagecontentJson {
      nodes {
        service_list {
          items {
            cta
            description
            id
            title
          }
          service_group_title
          service_group_id
          scroll_to_id
        }
        info_at_top {
          topics {
            link
            title
          }
        }
      }
    }
    allCoursesJson {
      edges {
        node {
          id
          url_path
        }
      }
    }
  }
`;
